// based on https://github.com/jcgertig/date-input-polyfill/blob/master/date-input-polyfill.js
// with custom styles
import "../styles/date-input-polyfill.scss"
import Input from "date-input-polyfill/input.js"

const useHtml5 = true

const addPickers = () => {
  Input.addPickerToOtherInputs()
  // Check if type="date" is supported.
  if (!useHtml5 || !Input.supportsDateInput()) {
    Input.addPickerToDateInputs()
  }
}

// Run the above code on any <input type="date"> in the document, also on dynamically created ones.
addPickers()

document.addEventListener(`DOMContentLoaded`, () => {
  addPickers()
})
